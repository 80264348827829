@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --color-background: 0 0% 100%;
    --color-foreground: 225 17% 14%;
    --color-cream: 48 24% 96%;
    --color-orange-background: 37 100% 72%;
    --color-orange-foreground: 23 100% 41%;
    --color-blue-background: 198 100% 62%;
    --color-blue-foreground: 225 59% 46%;
  }

  /* @media (prefers-color-scheme: dark) {
    :root {
      --color-background: 233 100% 4%;
      --color-foreground: 233 100% 97.5%;
      --color-accent: 303 75% 25%;
    }
  } */

  /* CSS */
  .font-sans {
    font-feature-settings:
      "ss01" on,
      "ss02" off,
      "ss03" off,
      "ss04" off,
      "ss05" off,
      "ss06" off,
      "ss07" off,
      "ss08" on;
  }

  .font-display {
    font-feature-settings:
      "ss01" on, /* Alternate R */
      "ss02" on, /* Alternate Y and y */
      "ss03" on, /* Alernative Q, Ø, and ø */
      "ss04" off, /* Alernative a */
      "ss05" on, /* Alernative 1, 2, and 4 */
      "ss06" on, /* Alternate !, ?, and * */
      "ss07" on, /* Round punctuation */
      "ss08" off; /* Alternate arrows */
  }
}

@layer utilities {
	.prose :where(iframe):not(:where([class~="not-prose"] *)) {
		@apply aspect-video w-full h-auto
	}

  .prose [id] {
    @apply relative;
  }

  .prose [id] > a {
    @apply opacity-0;
    @apply absolute;
    @apply right-full;
    @apply no-underline;
    @apply p-2;
    @apply -m-2;
    @apply mr-0;
  }

  .prose [id] > a:after {
    @apply content-["#"];
  }

  .prose [id]:hover > a {
    @apply opacity-50;
  }

  .prose [id]:hover > a:hover {
    @apply opacity-100;
  }
}

svg.tabler-icon:not([class*="stroke"]) {
  @apply !stroke-[1.75];
}

svg.tabler-icon:not([class*="size-"]):not([class*="h-"]) {
  @apply size-[1.4285em];
}

* {
  @apply border-foreground/10;
  @apply break-words;
  @apply scroll-mt-20;
}

a,
button,
label[for],
[href] [class*="group-hover"],
button [class*="group-hover"] {
  @apply transition;
  @apply ease-out;
  @apply duration-100;
  @apply cursor-pointer;
}
